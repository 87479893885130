import React from "react";

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.submitForm = this.submitForm.bind(this);
        this.state = {
            status: ""
        };
    }

    render() {
        const { status } = this.state;
        return (
        
            <div class="inline-flex items-center mt-16">
        <form
            onSubmit={this.submitForm}
            action="https://formspree.io/mpzqzjka"
            method="POST"
            class="w-full md:items-center max-w-xl bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
                
            <label className="text-xl font-dark">Enter your email:</label>
                <input 
                    type="email" 
                    name="email" 
                        class="text-center appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none" type="text" placeholder="example@example.com" />
                    <label className="text-xl font-dark" >Message:</label>
                <textarea 
                    type="text" 
                    name="message" 
                    class="text-center form-textarea mt-1 block w-full" rows="5" placeholder="How can we help you?"/>
                {status === "SUCCESS" ? <p>Thanks!</p> : <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-4 px-12 rounded focus:outline-none focus:shadow-outline" type="button">Send</button>}
                {status === "ERROR" && <p>Ooops! There was an error.</p>}
            </form>
            </div>
        );
    }

    submitForm(ev) {
        ev.preventDefault();
        const form = ev.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                this.setState({ status: "SUCCESS" });
            } else {
                this.setState({ status: "ERROR" });
            }
        };
        xhr.send(data);
    }
}