import React from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PrintIcon from '@material-ui/icons/Print';

export default [
  {
    title: 'No Driver Setup Required',
    icon: <SupervisorAccountIcon fontSize="large" />
  },
  {
    title: 'Automated Inspection Process',
    icon: <AssignmentTurnedInIcon fontSize="large" />
  },
  {
    title: 'Live Defect Digest For Operators',
    icon: <AnnouncementIcon fontSize="large" />
  },
  {
    title: 'Track Odometer, Location, and More',
    icon: <LocalGasStationIcon fontSize="large" />
  },
  {
    title: 'Comprehensive Starter Inspections',
    icon: <NoteAddIcon fontSize="large" />
  },
  {
    title: 'Operator Signature and Accountability',
    icon: <AssignmentIndIcon fontSize="large" />
  },
  {
    title: 'Automatic Generation of Paperwork',
    icon: <PrintIcon fontSize="large" />
  },
  {
    title: 'Fully Customizable Inspections',
    icon: <AssignmentIndIcon fontSize="large" />
  },
  {
    title: 'Automated Custom Notifications',
    icon: <AssignmentIndIcon fontSize="large" />
  },
];
