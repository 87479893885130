import React from 'react';
import HrefButton from './HrefButton';
import Card from './Card';

const PricingCard = ({ price }) => (
  <Card className="mb-8 bg-white opacity-100 border-indigo-400">
    <div key={price.plan} className="column">
      <section className="section">
        <div className="container mx-auto text-center">
          <h1 className="font-bold -mt-6 mb-6 text-2xl font-bold">{price.plan}</h1>
          <h2 className="font-bold mt-4 mb-6 text-5xl">{price.price}</h2>
          <p className="mt-4 mb-6 text-lg">{price.description}</p>
          <HrefButton className="mx-auto text-xl" href={price.buttonLink}>
            {price.buttonTitle}
          </HrefButton>
        </div>
      </section>
    </div>
  </Card>
);

export default PricingCard;
