export default [
  {
    title: 'Simple. Easy To Use. Peace Of Mind.',
    content:
      'CircleChek inspections allow me to stay up to date on the status of our plant operations, and vehicles. It\'s an important part of daily operations.',
    customerName: 'Cory Williamson',
    customerTitle: 'General Manager, Universal Sand & Gravel',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Vehicle safety should be a company\'s top priority',
    content:
      'If a wheel comes off a vehicle, it\'s now been made an absolute liability charge. ..Because it\'s such a serious danger that the company is fined regardless and there is no defence.',
    customerName: 'Sgt. Hank Dubee',
    customerTitle: 'Ontario Ministry of Transportation',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'Get the results you need with immediate savings',
    content:
      'With CircleChek you start saving time and money immediately. We cost less than pen and paper alternatives, eliminate needless paper work, and much more.',
    customerName: 'Jason and Bing',
    customerTitle: 'Founders of CircleChek',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
