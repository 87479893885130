import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import HrefButton from '../components/HrefButton';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import customerData from '../data/customer-data';
import priceData from '../data/pricing-data';
import featureData from '../data/features-data';
import ContactForm from '../components/ContactForm';
import PricingCard from '../components/PricingCard';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import DataCard from '../components/FeatureCard';
import SplitSection from '../components/SplitSection';

import Screen from '../assets/circlechek_app.svg';
import FeatureCard from '../components/FeatureCard';

export default props => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>CircleChek: All-In-One Vehicle Tracking</title>
      <meta
        name="description"
        content="CircleChek is creating hassle-free digitized daily/pre-ride inspection for heavy machinery and commercial vehicles. Your vehicles costs hundreds of thousands of dollars, how are you keeping track of them?"
      />
    </Helmet>
    <section className="pt-20 md:pt-20 mt-12 lg:-mt-20">
      <div className="container mx-auto lg:px-32 lg:flex">
        <div className="text-left text-center ml-0 lg:-ml-24 xl:ml-0 lg:text-left lg:w-3/4 lg:mt-32">
          <h1 className="text-2xl lg:text-3xl xl:text-4xl font-bold leading-none">
            Automate Daily Inspections.
          </h1>
          <h1 className="text-2xl lg:text-3xl xl:text-4xl mt-3 font-bold leading-none">
            Eliminate Paperwork.
          </h1>
          <h1 className="text-2xl lg:text-3xl xl:text-4xl mt-3 font-bold leading-none">
            It's never been easier.
          </h1>
          <p className="text-2xl lg:text-2xl xl:text-3xl mt-5 font-light">
            Signup & Add Vehicles.
            <br />
            Attach QR-Codes.
            <br />
            Scan & Inspect.
          </p>
          <div className="mx-auto lg:flex mt-8">
            <HrefButton className="text-lg" href="https://blog.circlechek.com/getting-started/">
              How It Works!
            </HrefButton>
            <HrefButton className="hidden lg:flex text-lg ml-8" href="https://app.circlechek.com">
              Start Free Today!
            </HrefButton>
          </div>
          <p className="mt-2 text-gray-600">
            No Fees. No Credit Card Required. We're here to help!
          </p>
        </div>
        <div className="mx-auto mt-8 sm:mt-0 sm:w-3/4 md:w-1/2">
          {/* <Img fluid={props.data.imageOne.childImageSharp.fluid} /> */}
          <Screen className="mx-auto mt-8 sm:mt-4 lg:-mt-2 xl:mt-4 w-full" />
        </div>
      </div>
    </section>
    <section id="Benefits" className="py-20 lg:pb-20 lg:pt-48 -mt-64">
      <div className="container mx-auto text-center -mt-64">
        <div className="mx-auto text-center -mt-64">
          <div className="flex flex-col sm:flex-row sm:-mx-20">
            <div className="flex-1 px-3">
              <Card className="mb-8 bg-white h-64 opacity-100 border-blue-400">
                <h1 className="font-bold text-xl -mt-4">Save Time by Automating Paperwork</h1>
                <p className="mt-2">
                  We do the paperwork for you so you start saving time and money right away.
                </p>
                <div className="mt-2 text-lg">
                  <a
                    href="https://blog.circlechek.com/getting-started/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Setup Inspections In 1 Minute! &#8594;
                  </a>
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 bg-white opacity-100 border-blue-400">
                <h1 className="font-bold text-xl -mt-4">Make Sure Inspections are Done</h1>
                <p className="mt-2">
                  You'll know when inspections are completed. Track issues from anywhere.
                </p>
                <div className="mt-2 text-lg">
                  <a
                    href="https://blog.circlechek.com/benefits/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    We're Here To Help Your Business! &#8594;
                  </a>
                </div>
              </Card>
            </div>
            <div className="flex-1 px-3">
              <Card className="mb-8 bg-white opacity-100 border-blue-400">
                <h1 className="font-bold text-xl -mt-4">Have Peace of Mind</h1>
                <p className="mt-2">
                  Protect your business from safety risks, vehicle defects, and reduce liabilty
                  events.
                </p>
                <div className="mt-2 text-lg">
                  <a href="https://roi.circlechek.com" target="_blank" rel="noopener noreferrer">
                    Stop Doing Paperwork! &#8594;
                  </a>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="Services" className="py-20 lg:pb-0 lg:py-40 lg:pt-0">
      <div className="container mx-auto text-center -mt-8">
        <h2 className="text-2xl lg:text-4xl font-bold">
        We Made Digital Inspections Simple...
        </h2>
        <p className="text-xl lg:text-2xl mt-3 font-light">
        CircleChek automates inspections to help maintain and track your vehicles.
        </p>
      </div>
    </section>
    <section id="ListFeatures" className="py-20 lg:pb-0 lg:py-40 lg:pt-10 -mt-8">
      <div className="container mx-auto text-center -mt-10">
        <div className="flex flex-wrap flex-col -mt-32 lg:mt-12 lg:flex-row">
          {featureData.map(feature => (
            <div key={feature.title} className="-mb-12 mx-auto text-center lg:w-1/2 xl:w-1/3">
              <FeatureCard feature={feature} />
            </div>
          ))}
        </div>
        <h2 className="text-xl lg:text-2xl mt-6 font-bold">
          <AnchorLink href="https://app.circlechek.com">
          Try CircleChek Free Today!
          </AnchorLink>
        </h2>
      </div>
      
    </section>
    
    
    <section id="testimonials" className="py-20 lg:py-40 -mt-24">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">What People Are Saying</LabelText>
        <div className="flex flex-col lg:mt-0 lg:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3 -mb-4 lg:mb-0">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>

    <section id="Services" className="py-20 lg:pb-0 lg:py-40 lg:pt-0">
      <div className="container mx-auto text-center -mt-32">
        <h2 className="text-xl lg:text-3xl font-bold">
          CircleChek is Hassle-Free!
        </h2>
        <p className="text-xl lg:text-2xl mt-3 font-light">
          &#8594; Operators Don't Need Accounts.
          <br />
          &#8594; Do Inspections Offline.
          <br />
          &#8594; Customizable Inspections.
          <br />
          &#8594; No Picking Vehicles Off a List.
          <br />
        </p>
        <h2 className="text-xl lg:text-2xl mt-3 font-bold">
          <AnchorLink href="https://app.circlechek.com">
          Try CircleChek Free Today!
          </AnchorLink>
        </h2>
      </div>
    </section>

    <section id="pricing" className="py-20 -mt-24 lg:pb-0 lg:py-40">
      <div className="container mx-auto text-center -mt-8">
        <h2 className="text-3xl lg:text-5xl font-bold">Pricing</h2>
        <p className="text-xl lg:text-2xl mt-6 font-light">
          CircleChek is designed for business of all shapes and sizes. 
          <br />
          There are no restrictions. We support unlimited number of operators and vehicles!
        </p>
      </div>
    </section>
    <section id="testimonials" className="py-20 -mt-32 lg:py-40">
      <div className="container mx-auto">
        <div className="flex flex-col lg:mt-0 lg:flex-row md:-mx-3">
          {priceData.map(price => (
            <div key={price.plan} className="flex-1 px-3 -mb-4 lg:mb-0">
              <PricingCard price={price} />
            </div>
          ))}
        </div>
        <p className="mt-0 text-center text-gray-600">
          {' '}
          No Hidden, Setup, Or Maintenance fees. Free To Begin. No Credit Card Required.
        </p>
      </div>
    </section>

    <section
      id="contact"
      className="container mx-auto my-10 py-24 bg-gray-200 rounded-lg text-center"
    >
      <h3 className="text-5xl font-semibold">Ready to see results?</h3>
      <p className="mt-8 text-xl font-dark">
        Contact us or leave a message below.
        <br />
        founders@circlechek.com
        <br />
        Tel: 1 (416)-792-4076
      </p>
      <ContactForm />
      {/* <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p> */}
    </section>
  </Layout>
);

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 600) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "checklist1.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "create.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "qrcode.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "completeInspection.jpg" }) {
      ...fluidImage
    }
  }
`;
