import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
// import LogoIcon from '../../svg/LogoIcon';
import LogoIcon from '../../assets/logo.svg';
// import Test from '../assets/Group.svg';
import HrefButton from '../HrefButton';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-row sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <LogoIcon />
        </div>
      </div>
      <div className="flex mt-4 sm:mb-2">
        <div className="pt-2">
          <a
            className="lg:flex px-2 text-lg"
            href="https://blog.circlechek.com/getting-started/"
            target="_blank"
            rel="noopener noreferrer"
          >
            How it Works
          </a>
        </div>
        <div className="pt-2">
          <a
            className="lg:flex px-2 text-lg"
            href="https://blog.circlechek.com/features/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Features
          </a>
        </div>
        <AnchorLink className="hidden flex px-2 pt-2 text-lg md:block" href="#pricing">
          Pricing
        </AnchorLink>
        <AnchorLink className="hidden md:block px-2 pt-2 text-lg" href="#contact">
          Contact
        </AnchorLink>
        <div className="md:block">
          <HrefButton className="text-md ml-8" href="https://app.circlechek.com/">
            Login
          </HrefButton>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
