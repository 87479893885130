import React from 'react';
import Google from '../../assets/google.svg';
import Apple from '../../assets/apple.svg';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-8 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Where to find us</h2>
        <p className="mt-5">
          Where to find us:
          <br />
          151 Charles Street, Suite 199,
          <br />
          Kitchener, ON,
          <br />
          N2G 1H6 Canada
        </p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a
              href="https://blog.circlechek.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://blog.circlechek.com/" target="_blank" rel="noopener noreferrer">
              Blog
            </a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Mobile App Download</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a
              href="https://play.google.com/store/apps/details?id=com.cc_v1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Google />
            </a>
          </li>
          <li>
            <a
              href="https://apps.apple.com/app/id1483785455"
              target="_blank"
              rel="noopener noreferrer"
            >
             <Apple />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
