export default [
  {
    plan: 'Per Vehicle Yearly Subscription.',
    price: '$17',
    description:
      'Cover each vehicle for the year with unlimited inspections.',
    buttonTitle: 'Signup Here!',
    buttonLink: 'https://app.circlechek.com/'
  },
  {
    plan: 'Try CircleChek Out Free! Start Today!',
    price: '$0',
    description:
      'Try out CircleChek free, no vehicle or operator limits.',
    buttonTitle: 'Signup Here!',
    buttonLink: 'https://app.circlechek.com/'
  },
  {
    plan: 'Enterprise Business Plan. One-Time Fee.',
    price: '$$$',
    description:
      'More than 30 vehicles in your fleet? Get the best coverage forever.',
    buttonTitle: 'Contact Us!',
    buttonLink: 'mailto:founders@circlechek.com'
  }
];

