import React from 'react';
import Grid from './Grid';

const FeatureCard = ({ feature }) => (
  <Grid className="mb-8 bg-white">
    <div
      className="text-xl"
      style={{
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        display: 'inline'
      }}
    >
      {feature.icon} {feature.title}
    </div>
  </Grid>
);

export default FeatureCard;
