import React from 'react';

const Grid = ({ className, children }) => (
  <div
    className={`p-6 ${className}`}
    style={{
      height: 'auto'
    }}
  >
    {children}
  </div>
);

export default Grid;